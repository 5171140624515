<template>
  <li
    class="nav__items"
    :class="{ 'is-active' : active }"
    @click="setGroupName(group)"
  >
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: 'nav-item',

  props: {
    group: [String],
    multipleRoutes: [Boolean]
  },

  inject: {
    handleNav: {}
  },

  data () {
    return {
      defaultPath: null,
      active: false,
      withSubNav: false
    }
  },

  watch: {
    async '$route' ($router) {
      this.validateRoute()
    }
  },

  mounted () {
    this.validateRoute()
  },

  methods: {
    validateRoute () {
      this.$nextTick(() => {
        if (!this.withSubNav) {
          if (this.$route.path === this.$children[0].defaultPath) {
            this.active = true
          } else {
            this.active = false
          }
        }
      })
    },

    setGroupName (group) {
      this.handleNav.group = group
    }
  }
}
</script>
